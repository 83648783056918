<template>
  <div class="pa-12">
    <CompanyTable2/>
  </div>
</template>

<script>
import CompanyTable2 from '../components/CompanyTable2.vue';

export default {
  name: 'Home',
  components: {
    CompanyTable2,
  },
};
</script>
