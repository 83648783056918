<template>
  <div>
    <h2>Test</h2>
    <ApolloQuery
      :query="gql => gql`
        query homes ($query: HomeQueryInput) {
          homes (query: $query) {
            companies {
              companyId
              email
              logo
              name
            }
          }
        }
      `"
    >
      <!-- TODO -->
      <template v-slot="{ result: { loading, error, data } }">
        <!-- Loading -->
        <div v-if="loading" class="loading apollo">Loading...</div>

        <!-- Error -->
        <div v-else-if="error" class="error apollo">An error occurred</div>

        <!-- Result -->
        <div v-else-if="data" class="result apollo">
          <!-- <p>{{ data }}</p> -->
          <v-data-table
            :headers="headers"
            :items="homes ? homes[0].companies : []"
            :items-per-page="10"
            class="elevation-1"
          ></v-data-table>
        </div>

        <!-- No result -->
        <div v-else class="no-result apollo">No result :(</div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import COMPANIES from '../graphQL/Users.gql';
import HOMES from '../graphQL/Homes.gql';

export default {
  data() {
    return {
      msg: 'Hi there!',
      headers: [
        {
          text: 'Company name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email' },
        { text: 'logo', value: 'logo' },
      ],
    };
  },
  apollo: {
    companies: COMPANIES,
    homes: HOMES,
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
